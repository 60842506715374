import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/scss";
import "swiper/css/free-mode";
import "swiper/css/grid";
import "swiper/css/navigation";

import SwiperCore, {
    FreeMode, Grid, Navigation
} from 'swiper';
SwiperCore.use([FreeMode, Grid, Navigation]);
export default async ({ app, router}) => {
    app.component('swiper', Swiper)
    app.component('swiper-slide', SwiperSlide)
}

