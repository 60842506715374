import axiosApi, {api} from "axios";
import {useUser} from "src/stores/user";

const baseUrl = process.env.API_ENDPOINT;
import {boot} from "quasar/wrappers";

let axios = axiosApi.create({baseURL: baseUrl + '/api'});
let auth = {};
export default boot(async ({app}) => {

  const store = useUser()

  auth = {
    async login(email, password, captcha) {
      return await axios.post(`auth/signin`, {
        email: email,
        password: password,
        'g-recaptcha-response': captcha
      }, {}).then((res) => {
        store.setToken(res.data.token);
        document.location.reload()
      }).catch(err => {
        console.log(err)
      })
    },
    async returnToAdminPanel() {
      store.setToken(store.admin_token);
      if (!store.admin_path) {
        document.location.reload()
      } else {
        document.location.href = store.admin_path
      }
    },
    async loginViaToken(token) {
      store.setToken(token);
      document.location.reload()
    },
    async loginAsUser(token, path) {
      store.setAdminToken(store.token, path);
      store.setToken(token);
      document.location.reload()
    },
    async logout() {
      return await axios.get(`auth/logout`, {
        headers: {
          'Authorization': 'Bearer ' + store.token
        }
      }).then((res) => {
        store.setToken('');
        store.setUser({});
        document.location.reload();
      });
    },
    hasAdminToken() {
      console.log(store.admin_token)
      return store.hasAdminToken
    },
    isAuthenticated() {
      return store.isAuthenticated;
    },
    isGuest() {
      return !store.isAuthenticated;
    },
    user() {
      return store.user;
    },
    canAny(permissions) {
      let allow = false
      if (store.user.permissions) {
        permissions.forEach((permission) => {
          if (store.user.permissions.indexOf(permission) !== -1) allow = true;
        })
      }
      if ((permissions.indexOf('guest') !== -1) && this.isGuest()) allow = true;
      return allow;
    },
    async fetchUser() {
      if (store.token) {
        try {

          await axios.get(`auth/user`, {
            headers: {
              'Authorization': 'Bearer ' + store.token
            }
          }).then((res) => {
            store.setUser(res.data);
          });
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  if (store.token) {
    axios = axiosApi.create({
      baseURL: baseUrl + '/api', headers: {
        'Authorization': 'Bearer ' + store.token
      }
    });
    app.config.globalProperties.$api = axios;
  } else {
    axios = axiosApi.create({baseURL: baseUrl + '/api'});
    axios.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      //if(error.response?.status === 401) {
      // router.push({name: 'login-page'})
      //}
      return Promise.reject(error);
    });
    app.config.globalProperties.$api = axios;
  }


  await auth.fetchUser();


  app.config.globalProperties.$auth = auth;
});

export {auth, axios};
