<template>
  <router-view />
</template>
<script>
import { defineComponent } from "vue";
import * as Sentry from "@sentry/browser";

export default defineComponent({
  name: "App",
  created() {
    if(this.$auth?.user()?.id) {
      Sentry.setUser({ email: this.$auth?.user()?.email, id:  this.$auth?.user()?.id});
    }
  },
});
</script>
