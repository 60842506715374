const routes = [

  {
    path: "/tournament",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        name: 'tournament-page',
        component: () => import("pages/TournamentPage.vue")
      },
    ],
  },
  {
    path: "/tournament/rules",
    component: () => import("layouts/MainLayoutLight.vue"),
    children: [
      {
        path: "",
        name: 'tournament-rules-page',
        component: () => import("pages/TournamentRulesPage.vue")
      },
    ],
  },
  {
    path: "/links",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/Index.vue")
      },
    ],
  },
  {
    path: "/",
    component: () => import("layouts/MainLayoutAuthorizedLight.vue"),
    children: [
      {
        name: 'main',
        path: "",
        component: () => import("pages/MainAuthorized.vue")
      },
    ]
  },
  {
    path: "/",
    component: () => import("layouts/MainLayoutAuthorized.vue"),
    children: [
      {
        name: 'waters',
        path: "/water",
        component: () => import("pages/Waters.vue")
      },
      {
        name: 'water-page',
        path: "/water/:slug",
        component: () => import("pages/Water.vue")
      },
      {
        name: 'cities',
        path: "/city",
        component: () => import("pages/Cities.vue")
      },
      {
        name: 'city-page',
        path: "/city/:slug",
        component: () => import("pages/City.vue")
      },
      {
        name: 'fishes',
        path: "/fish",
        component: () => import("pages/Fishes.vue")
      },
      {
        name: 'fish-page',
        path: "/fish/:slug",
        component: () => import("pages/Fish.vue")
      },
      {
        name: 'reports-page',
        path: "/reports",
        component: () => import("pages/Reports.vue")
      },
      {
        name: "create-report",
        path: "/reports/guide/:guideId/create",
        meta: {
          permissions: [
            'guide', 'fisherman'
          ]
        },
        component: () => import("pages/ReportForm.vue"),
      },
      {
        name: "edit-report",
        path: "/reports/guide/:reportId/edit",
        meta: {
          permissions: [
            'guide', 'fisherman'
          ]
        },
        component: () => import("pages/ReportForm.vue"),
      },
      {
        name: "create-report-fisherman",
        path: "/reports/create",
        meta: {
          permissions: [
            'fisherman'
          ]
        },
        component: () => import("pages/ReportForm.vue"),
      },
      {
        name: 'report-page',
        path: "/reports/:id",
        component: () => import("pages/Report.vue")
      },
      {
        name: 'news-page',
        path: "/news/:id",
        meta: {
          title: 'Страница новостей - Новость - Рыбалка с гидом'
        },
        component: () => import("pages/NewsPage.vue")
      },
      {
        path: "/blogs",
        component: () => import("pages/Page301.vue"),
      },
      {
        name: 'create-tour',
        path: "/account/tour/create",
        component: () => import("pages/TourForm.vue"),
        meta: {
          permissions: [
            'guide'
          ]
        },
      },
      {
        name: 'notifications',
        path: "notifications",
        meta: {
          permissions: [
            'guide', 'fisherman', 'admin', 'moder'
          ]
        },
        component: () => import("pages/Notices.vue")
      },
      {
        name: 'private-chat',
        path: "/chat/:userId",
        component: () => import("pages/Dialog.vue"),
        meta: {
          permissions: [
            'guide', 'fisherman', 'admin', 'moder'
          ]
        },
      },
      {
        name: 'chats',
        path: "/chats",
        component: () => import("pages/Dialogs.vue"),
        meta: {
          permissions: [
            'guide', 'fisherman', 'admin', 'moder'
          ]
        },
      },
      {
        name: 'edit-tour',
        path: "/account/tour/edit/:id",
        component: () => import("pages/TourForm.vue"),
        meta: {
          permissions: [
            'guide'
          ]
        },
      },
      {
        name: 'booking',
        path: "/account/booking-history",
        component: () => import("pages/BookingHistory.vue"),
        meta: {
          permissions: [
            'guide', 'fisherman'
          ]
        },
      },
      {
        name: 'fav-users',
        path: "/favorites",
        component: () => import("pages/FavoriteUsers.vue"),
        meta: {
          permissions: [
            'guide', 'fisherman'
          ]
        },
      },
      {
        name: 'account-info',
        path: "/account/info",
        meta: {
          permissions: [
            'guide', 'fisherman'
          ]
        },
        component: () => import("pages/AccountInfo.vue")
      },
      {
        name: 'account-settings',
        path: "/account/settings",
        meta: {
          permissions: [
            'guide', 'fisherman'
          ]
        },
        component: () => import("pages/AccountSettings.vue")
      },
      {
        name: 'account-subscriptions',
        path: "/account/subscriptions",
        meta: {
          permissions: [
            'guide'
          ]
        },
        component: () => import("pages/AccountSubscriptionActive.vue")
      },

    ]
  },
  {
    path: "/",
    component: () => import("layouts/MainLayoutLight.vue"),
    children: [
      {
        path: "login",
        name: "login-page",
        meta: {
          title: 'Вход - Рыбалка с гидом',
          permissions: ["guest"]
        },
        component: () => import("pages/Login.vue")
      },
      {
        path: "registration",
        name: 'registration-page',
        meta: {
          title: 'Регистрация - Рыбалка с гидом',
          permissions: ["guest"]
        },
        component: () => import("pages/Registration.vue")
      },
      {
        path: "success",
        meta: {
          permissions: ["guest"]
        },
        name: 'success-registration',
        component: () => import("pages/RegistrationSuccess.vue")
      },
      {
        path: "password-recovery",
        name: "password-recovery",
        meta: {
          title: 'Восстановление пароля - Рыбалка с гидом',
          permissions: ["guest"]
        },
        component: () => import("pages/PasswordRecovery.vue")
      },
      {
        path: "password-recovery-complete",
        meta: {
          permissions: ["guest"]
        },
        component: () => import("pages/PasswordRecoveryComplete.vue")
      },
      {
        path: "complete-registration/:userId",
        meta: {
          permissions: ["guest"]
        },
        component: () => import("pages/CompleteRegistration.vue")
      },
    ]
  },
  {
    path: "/",
    component: () => import("layouts/GuideProfileLayout.vue"),
    children: [
      {
        path: "/guide/info/:idOrSlug",
        meta: {
          title: 'Профиль гида - Рыболовные гиды - Рыбалка с гидом',
        },
        name: 'profile-old-link',
        redirect: { name: 'profile' },
      },
    ]
  },
  {
    path: "/",
    component: () => import("layouts/GuideProfileLayout.vue"),
    children: [
      {
        path: "/guide/:idOrSlug",
        name: 'profile',
          component: () => import("pages/GuideProfilePro.vue")
      },
    ]
  },
  {
    path: "/",
    component: () => import("layouts/MainLayoutLight.vue"),
    children: [
      {
        path: "/guide/:idOrSlug/booking:bookingDate?:bookingPlace?",
        meta: {
          permissions: ["fisherman"]
        },
        name: 'guide-booking',
        component: () => import("pages/GuideBookingPage.vue")
      },
    ],
  },
  {
    path: "/",
    component: () => import("layouts/ClientProfileLayout.vue"),
    children: [
      {
        path: "/client/:idOrSlug",
        name: 'client-profile',
        component: () => import("pages/FisherProfileSelf.vue")
      },
    ]
  },
  {
    path: "/guides",
    component: () => import("layouts/MainLayoutAuthorized.vue"),
    children: [
      {
        path: "",
        query: ['search', 'fish', 'dateRange', 'strictDates'],
        name: 'guides-search',
        component: () => import("pages/Search.vue")
      },
    ]
  },
  {
    path: "/tours",
    component: () => import("layouts/MainLayoutAuthorized.vue"),
    children: [
      {
        path: "",
        name: 'tours-search',
        component: () => import("pages/SearchTours.vue")
      },
    ]
  },
  {
    path: "/hot-tours",
    component: () => import("layouts/MainLayoutAuthorized.vue"),
    children: [
      {
        path: "",
        meta: {
          title: 'Горящие туры - Рыбалка с гидом'
        },
        name: 'hot-tours-search',
        component: () => import("pages/SearchTours.vue")
      },
    ]
  },
  {
    path: "/sharing",
    component: () => import("layouts/MainLayoutAuthorized.vue"),
    children: [
      {
        path: "",
        meta: {
          title: 'Рыбшеринг - Рыбалка с гидом'
        },
        name: 'sharing-tours-search',
        component: () => import("pages/SearchTours.vue")
      },
    ]
  },
  {
    path: "/fisher",
    component: () => import("layouts/MainLayoutAuthorized.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/FisherProfile.vue")
      },
    ]
  },
  {
    path: "/fisher-self",
    component: () => import("layouts/MainLayoutAuthorized.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/FisherProfileSelf.vue")
      },
    ]
  },
  {
    path: "/tour",
    component: () => import("layouts/MainLayoutAuthorized.vue"),
    children: [
      {
        name: 'tour',
        path: ":id",
        meta: {
          title: 'Страница тура - Туры - Рыбалка с гидом'
        },
        component: () => import("pages/Tour.vue")
      },
    ]
  },
  {
    path: "/privacy",
    component: () => import("layouts/MainLayoutAuthorized.vue"),
    children: [
      {
        path: "",
        meta: {
          title: 'Правовая информация - Рыбалка с гидом'
        },
        component: () => import("pages/Privacy.vue")
      },
    ]
  },
  {
    path: "/personal",
    component: () => import("layouts/MainLayoutAuthorized.vue"),
    children: [
      {
        path: "",
        meta: {
          title: 'Политика обработки персональных данных - Рыбалка с гидом'
        },
        component: () => import("pages/Personal.vue")
      },
    ]
  },
  {
    path: "/review-form",
    component: () => import("layouts/MainLayoutAuthorized.vue"),
    children: [
      {
        name: 'review-form-fisherman',
        meta: {
          permissions: [
            'fisherman'
          ]
        },
        path: "",
        component: () => import("pages/ReviewForm.vue")
      },
      {
        name: 'review-form',
        meta: {
          permissions: [
            'fisherman'
          ]
        },
        path: ":guideId",
        component: () => import("pages/ReviewForm.vue")
      },
    ]
  },
  {
    path: "/503",
    component: () => import("layouts/MainLayoutAuthorized.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/503.vue")
      },
    ]
  },
  {
    path: "/admin",
    component: () => import("layouts/AdminLayout.vue"),
    meta: {
      permissions: ["admin", "moder", "seo"]
    },
    children: [
      {
        path: "",
        component: () => import("pages/admin/Dashboard.vue")
      },
      {
        path: "user",
        component: () => import("pages/admin/users/AdminUsersPage.vue"),
        name: 'admin-user',
        meta: {
          permissions: ["admin", "moder"]
        },
      },
      {
        path: "user-reports",
        component: () => import("pages/admin/users/AdminUsersReportsPage.vue"),
        name: 'admin-user-reports',
        meta: {
          permissions: ["admin"]
        },
      },
      {
        path: "review",
        component: () => import("pages/admin/reviews/ReviewPage.vue"),
        name: 'admin-review',
        meta: {
          permissions: ["admin", "moder"]
        },
      },
      {
        path: "report",
        component: () => import("pages/admin/reports/ReportPage.vue"),
        name: 'admin-report',
        meta: {
          permissions: ["admin", "moder"]
        },
      },
      {
        path: "blog",
        component: () => import("pages/admin/blogs/BlogPage.vue"),
        name: 'admin-blog'
      },
      {
        path: "tour",
        component: () => import("pages/admin/tours/TourPage.vue"),
        name: 'admin-tour',
        meta: {
          permissions: ["admin", "moder"]
        },
      },
      {
        path: "stat",
        component: () => import("pages/admin/stats/StatPage.vue"),
        name: 'admin-stat',
        meta: {
          permissions: ["admin", "moder"]
        },
      },
      {
        path: "paid-service",
        component: () => import("pages/admin/reference/paid-service/PaidService"),
        name: 'admin-paid-service',
        meta: {
          permissions: ["admin"]
        },
      },
      {
        path: "reference/fish",
        component: () => import("pages/admin/reference/fish/Fish"),
        name: 'admin-reference-fish'
      },
      {
        path: "reference/fish/type",
        component: () => import("pages/admin/reference/fish/type/FishType"),
        name: 'admin-reference-fish-type'
      },
      {
        path: "reference/fish/method",
        component: () => import("pages/admin/reference/fish/method/FishingMethod"),
        name: 'admin-reference-fish-method'
      },
      {
        path: "reference/water/type",
        component: () => import("pages/admin/reference/water/type/WaterType"),
        name: 'admin-reference-water-type'
      },
      {
        path: "reference/water/db",
        component: () => import("pages/admin/reference/water/db/WaterDb"),
        name: 'admin-reference-water-db'
      },
      {
        path: "reference/water",
        component: () => import("pages/admin/reference/water/Water"),
        name: 'admin-reference-water'
      },
      {
        path: "reference/city",
        component: () => import("pages/admin/reference/city/City"),
        name: 'admin-reference-city'
      },
      {
        path: "settings",
        component: () => import("pages/admin/settings/AdminSettingsPage"),
        name: 'admin-settings',
        meta: {
          permissions: ["admin", "moder"]
        },
      },
      {
        path: "competition",
        component: () => import("pages/admin/competition/CompetitionPage"),
        name: 'admin-competition',
        meta: {
          permissions: ["admin", "moder"]
        },
      },
      {
        path: "competition-request",
        component: () => import("pages/admin/competition/competition-request/CompetitionPage"),
        name: 'admin-competition-request',
        meta: {
          permissions: ["admin", "moder"]
        },
      },
      {
        path: "competition-period",
        component: () => import("pages/admin/competition/period/PeriodPage.vue"),
        name: 'admin-competition-period',
        meta: {
          permissions: ["admin", "moder"]
        },
      },
      {
        path: "competition-prize",
        component: () => import("pages/admin/competition/prize/PrizePage.vue"),
        name: 'admin-competition-prize',
        meta: {
          permissions: ["admin", "moder"]
        },
      },
      {
        path: "mail",
        component: () => import("pages/admin/mail/AdminMailPage"),
        name: 'admin-mail',
        meta: {
          permissions: ["admin", "moder"]
        },
      },
      {
        path: "mail-log",
        component: () => import("pages/admin/mail/AdminMailLogPage"),
        name: 'admin-mail-log',
        meta: {
          permissions: ["admin", "moder"]
        },
      },
      {
        path: "login",
        meta: {
          permissions: ['guest']
        },
        component: () => import("pages/admin/auth/Login.vue")
      },
    ],
  },


  // Always leave this as last one
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    name: "404",
    component: () => import("layouts/MainLayoutLight.vue"),
    children: [
      {
        path: "",
        name: '404-all',
        component: () => import("pages/404.vue")
      },
    ],
  }
];

export default routes;
