import {boot} from "quasar/wrappers";
import {VueReCaptcha} from 'vue-recaptcha-v3'

export default boot(({app}) => {

  app.use(VueReCaptcha, {
    siteKey: '6Lf4OngjAAAAALj1AtLcXVlPUqvn15HLDv4ti0G4',
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: true,
      useEnterprise: true,
    }
  });
});