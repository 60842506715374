import { defineStore } from 'pinia'
import {cookies} from "boot/cookies";
export const useUser = defineStore('user', {
  state: () => ({
    _user: {},
    _token: '',
    _admin_token: '',
    _admin_path: '',
  }),
  getters: {
    user() {
      return this._user;
    },
    token() {
      return this._token ? this._token : cookies.get('auth_token')
    },
    admin_token() {
      return this._admin_token ? this.admin_token : cookies.get('auth_admin_token')
    },
    admin_path() {
      return this._admin_path ? this._admin_path : cookies.get('auth_admin_path')
    },
    isAuthenticated() {
      return !!this._user.id
    },
    hasAdminToken() {
      return !!this.admin_token
    }
  },
  actions: {
    increaseUnreadMessages() {
      this._user.unread_messages_count++;
    },
    updateUnreadMessages(c) {
      this._user.unread_messages_count = c;
    },
    updateUnreadNotifications(c) {
      this._user.unread_notifications_count = c;
    },
    setToken(token) {
      this._token = token;
      document.cookie = "auth_token="+token+"; expires=Thu, 18 Dec 2033 12:00:00 UTC; path=/";
    },
    setAdminToken(token, path) {
      this._admin_token = token;
      this._admin_path = path;
      document.cookie = "auth_admin_token="+token+"; expires=Thu, 18 Dec 2033 12:00:00 UTC; path=/";
      document.cookie = "auth_admin_path="+path+"; expires=Thu, 18 Dec 2033 12:00:00 UTC; path=/";
    },
    setUser(user) {
      this._user = user;
    }
  },
})
