import {boot} from "quasar/wrappers";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

export default boot(({app}) => {
  Sentry.init({
    dsn: "https://7feb6d51dc054587a6a51f6bed60d876@o4505522410881024.ingest.sentry.io/4505522412912640",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.25,
  });
});
