import { Cookies } from 'quasar'

let cookies = {}
export default async ({ ssrContext }) => {
    cookies = process.env.SERVER
        ? Cookies.parseSSR(ssrContext)
        : Cookies
}

export {cookies};

