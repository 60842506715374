import {useUser} from "src/stores/user";

function isAdminOrModer(userPermissions, routePermissions) {
  if(userPermissions.indexOf('admin') !== -1 || userPermissions.indexOf('moder') !== -1 || userPermissions.indexOf('seo') !== -1) {
    if(routePermissions.indexOf('guest') !== -1) return true;
  }
}

function isUser(userPermissions, routePermissions) {
  if(userPermissions.indexOf('fisherman') !== -1 || userPermissions.indexOf('guide') !== -1) {
    if(routePermissions.indexOf('guest') !== -1) return true;
  }
}

export default async ({ router, store }) => {
  const ADMIN_PATH = '/admin';
  const ADMIN_LOGIN_PATH = '/admin/login';
  const USER_LOGIN_PATH = '/login';
  const USER_PATH = '/';

  router.beforeEach((to, from, next) => {
    if (to.meta.permissions) {
      const store = useUser()

      if(store.user.permissions) {

        if(isAdminOrModer(store.user.permissions, to.meta.permissions)) return next(ADMIN_PATH);
        if(isUser(store.user.permissions, to.meta.permissions)) return next(USER_PATH);

        let allowed = false;
        to.meta.permissions.forEach((permission) => {
          if(store.user.permissions.indexOf(permission) !== -1) allowed = true;
        })
        if(allowed) return next();
      } else {
        if(to.meta.permissions.indexOf('guest') !== -1) return next();
      }

      if(to.path.split('/')[1] === 'admin') {
        return next(ADMIN_LOGIN_PATH);
      } else {
        return next(USER_LOGIN_PATH);
      }


    } else {
      return next();
    }
  });
};
